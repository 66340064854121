body {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #fff;
  color: black; /* Change text color to black */
}

.container {
  max-width: 900;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  position: relative;
  color: black;
}

.store-info {
  margin-bottom: 20px;
  color: black;
}

.store-info img {
  width: 120px;
  height: 60px;
  display: block;
  top: 10px;
  right: 5px;
  color: black;
}

.store-info h2 {
  font-size: 12px;
  margin-bottom: 5px;
  color: black;
}

.store-info p {
    font-size: 12px;
  margin: 5px 0px;
    margin-right:10px;
  color: black;
}

.receipt-details {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  font-size: 12px;
  color: black;
}

.receipt-details p {
  font-size: 12px;
  margin: 5px 0;
  color: black;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: black;
    font-size: 12px; 
}

.table th, .table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  color: black;
    font-size: 12px; 

}

.table th {
  background-color: #e0e0e0;
  font-weight: bold;
  color: black;
    font-size: 12px; 
}

.total {
  font-weight: bold;
  text-align: right;
  color: black;
    font-size: 12px; 
}

.tax-details {
  font-size: 12px; 
  margin-top: 20px;
  text-align: center;
  color: black;
}

.tax-details p {
    font-size: 12px; 
  margin-bottom: 5px;
  color: black;
}

.signature {
    font-size: 12px; 
  text-align: right;
  margin-top: 20px;
  color: black;
}

.signature p {
    font-size: 12px; 
  margin-bottom: 5px;
  color: black;
}

.signature p:first-child {
    font-size: 12px; 
  margin-right: 80px;
  font-weight: bold;
  color: black;
}

.table th:nth-child(1),
.table td:nth-child(1),
.table th:nth-child(2),
.table td:nth-child(2),
.table th:nth-child(3),
.table td:nth-child(3) {
  width: 25%;
}